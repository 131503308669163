import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4dece27c&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=4dece27c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dece27c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseRichtext: require('/app/components/base/Richtext.vue').default,UiProjectsTable: require('/app/components/ui/ProjectsTable.vue').default})
