//
//
//
//
//
//
//
//
//
//
//

import { isRichtextFilled } from '~/utils/helpers';

export default {
  name: "BaseRichtext",
  props: {
    doc: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isRichtext() {
      return isRichtextFilled(this.doc);
    },
  },
};
