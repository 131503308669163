//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapMutations} from 'vuex';
import {useStoryblokBridge} from "@storyblok/nuxt-2";
import seoHead from '~/mixins/seo-head';
import eventBus from "~/plugins/eventBus";

export default {
  name: 'Pages_',
  mixins: [seoHead],
  async asyncData({error, store}) {
    const isHome = true;
    const path = '/home';
    try {
      const story = await store.dispatch('page/getPageStory', {
        path,
      });
      return {
        story,
        isHome
      }
    } catch (err) {
      console.error(err);
      error(err);
    }
  },
  computed: {
    content() {
      return this.story?.content
    },
    heroVideo() {
      return this.content?.video
    },
    heroPoster() {
      return this.content?.poster?.filename ?? ''
    },
  },
  mounted() {
    if (this.story?.id) {
      useStoryblokBridge(this.story.id, (newStory) => {
        this.story = newStory;
        this.setPageStory(newStory);
      });
    }

    eventBus.$emit('updateHeaderTheme', this.story.content.dark_header_theme)
  },
  methods: {
    ...mapMutations('page', ['setPageStory']),
  },
  middleware({route, store}) {
    const {fullPath} = route;

    store.commit('setBackHomeSlug', fullPath);
  },
}
